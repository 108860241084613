import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../styles/ReservationsPage.css';
import Footer from '../components/footer';

const termsAndConditions = `
  Welcome to Swing Golf Sim and Lounge (“Venue”). These Terms and Conditions govern your use of the Venue's facilities and services. 
  By entering and using the Venue, you agree to comply with and be bound by these Terms and Conditions.

  Advanced booking is recommended to ensure the availability of simulators. Reservations can be made online, by phone, or in person. 
  A valid credit card is required to secure a reservation. Cancellations must be made at least 24 hours in advance to avoid cancellation fees.

  All fees for simulator usage, food, and beverages must be paid in full before leaving the Venue. Prices are subject to change without prior notice. 
  We accept cash, credit, and debit cards.

  Guests must respect the Venue, staff, and other patrons at all times. Any form of harassment, abuse, or disruptive behaviour will not be tolerated 
  and may result in removal from the Venue.

  Guests must follow all instructions provided by staff regarding the use of golf simulators. Guests are responsible for any damages caused to the 
  simulators due to reckless behaviour.

  Outside food and beverages are not permitted. The Venue offers a variety of food and beverages for purchase. Responsible consumption of alcohol 
  is expected; the Venue reserves the right to refuse service to intoxicated guests.

  The Venue is not responsible for any personal injury, loss, or damage to personal property while on the premises. Guests assume all risks associated 
  with the use of the Venue and its facilities.

  Personal information collected during bookings will be used in accordance with our Privacy Policy. We do not share personal information with third parties without consent.

  The Venue reserves the right to modify these Terms and Conditions at any time. Changes will be effective immediately upon posting on our website or at the Venue.

  These Terms and Conditions shall be governed by and construed in accordance with the laws of Trinidad and Tobago.

  For any questions or concerns regarding these Terms and Conditions, please contact us at [contact details].

  By using our facilities, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions.
`;

const generateHourlyIntervals = (dayOfWeek) => {
  const intervals = [];
  const startHour = 11;
  const endHour = dayOfWeek === 5 || dayOfWeek === 6 ? 23 : 22;

  for (let i = startHour; i <= endHour; i++) {
    const hour = i % 12 === 0 ? 12 : i % 12;
    const ampm = i < 12 || i === 24 ? 'AM' : 'PM';
    const formattedHour = hour < 10 ? `0${hour}` : hour;
    intervals.push(`${formattedHour}:00 ${ampm}`);
  }
  return intervals;
};

const combineDateTime = (date, time) => {
  const [hourMinute, ampm] = time.split(' ');
  let [hour, minute] = hourMinute.split(':');
  hour = parseInt(hour, 10);
  minute = parseInt(minute, 10);

  if (ampm === 'PM' && hour !== 12) {
    hour += 12;
  } else if (ampm === 'AM' && hour === 12) {
    hour = 0;
  }

  const combinedDate = new Date(`${date}T${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}:00`);
  console.log('Combined date and time:', combinedDate);
  return combinedDate;
};

const getClosingTime = (date) => {
  const closingTime = new Date(date);
  const dayOfWeek = closingTime.getDay();

  if (dayOfWeek === 5 || dayOfWeek === 6) {
    closingTime.setHours(24, 0, 0, 0);
  } else {
    closingTime.setHours(23, 0, 0, 0);
  }

  return closingTime;
};

const ReservationsPage = () => {
  useEffect(() => {
    document.body.classList.add('reservations-page');
    return () => {
      document.body.classList.remove('reservations-page');
    };
  }, []);

  const navigate = useNavigate();

  const [reservationDetails, setReservationDetails] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    date: '',
    time: '',
    duration: 1,
    amount: 300,
    simulator: 1,
    lesson: 0,
  });

  const [availableSlots, setAvailableSlots] = useState([]);
  const [nextAvailableSlot, setNextAvailableSlot] = useState(null);
  const [intervals, setIntervals] = useState([]);
  const [maxDuration, setMaxDuration] = useState(1);
  const [showPopup, setShowPopup] = useState(false); // Show popup
  const [isAgreed, setIsAgreed] = useState(false);

  useEffect(() => {
    fetchAvailableSlots();
  }, []);

  useEffect(() => {
    if (reservationDetails.date) {
      const selectedDate = new Date(reservationDetails.date);
      const dayOfWeek = selectedDate.getDay();
      setIntervals(generateHourlyIntervals(dayOfWeek));
    }
  }, [reservationDetails.date]);

  const fetchAvailableSlots = async () => {
    try {
      console.log('Fetching available slots...');
      const response = await fetch(`${process.env.REACT_APP_NGROK}/api/bookings/bookings`);
      const data = await response.json();
      console.log('Available slots:', data);
      setAvailableSlots(data);
    } catch (error) {
      console.error('Error fetching available slots:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setReservationDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleCheckoutClick = () => {
    setShowPopup(true); // Show the popup with terms and conditions
  };

  const handleAgreeChange = () => {
    setIsAgreed((prevState) => !prevState);
  };

  const handleContinue = () => {
    if (!isAgreed) return;
    setShowPopup(false); // Close the popup after agreement
    handleCheckout();
  };

  const handleCheckout = async () => {
    if (!reservationDetails.date || !reservationDetails.time || !reservationDetails.firstName || !reservationDetails.lastName || !reservationDetails.email || !reservationDetails.phone) {
      alert('Please fill in all the fields.');
      return;
    }

    const combinedDateTime = combineDateTime(reservationDetails.date, reservationDetails.time);

    const now = new Date();
    if (combinedDateTime <= now) {
      alert('You cannot book for a past date or time.');
      return;
    }

    const oneHourBeforeBooking = new Date(combinedDateTime.getTime() - 60 * 60 * 1000);
    if (now > oneHourBeforeBooking) {
      alert('Bookings must be made at least one hour in advance.');
      return;
    }

    const bookingEndTime = new Date(combinedDateTime);
    bookingEndTime.setTime(combinedDateTime.getTime() + reservationDetails.duration * 60 * 60 * 1000);

    const closingTime = getClosingTime(combinedDateTime);
    if (bookingEndTime > closingTime) {
      alert('The selected time slot exceeds the closing time. Please select a different time or reduce the duration.');
      return;
    }

    let totalAmount = 0;
    let currentTime = new Date(combinedDateTime);

    for (let i = 0; i < reservationDetails.duration; i++) {
      const hour = currentTime.getHours();
      const dayOfWeek = currentTime.getDay();
      const isOffPeak = dayOfWeek >= 1 && dayOfWeek <= 5 && hour >= 11 && hour < 17;

      totalAmount += isOffPeak ? 200 : 300;
      currentTime.setHours(currentTime.getHours() + 1);
    }

    setReservationDetails((prevDetails) => ({
      ...prevDetails,
      amount: totalAmount,
    }));

    const bookingDetails = { ...reservationDetails, date: combinedDateTime.toISOString() };
    const encodedBookingDetails = encodeURIComponent(JSON.stringify(bookingDetails));

    try {
      const response = await axios.post(`${process.env.REACT_APP_NGROK}/api/bookings/initiate`, {
        firstName: reservationDetails.firstName,
        lastName: reservationDetails.lastName,
        email: reservationDetails.email,
        phone: reservationDetails.phone,
        date: combinedDateTime.toISOString(),
        amount: totalAmount,
        duration: reservationDetails.duration,
        simulator: reservationDetails.simulator,
        lesson: reservationDetails.lesson,
        key: '458xf4izac',
        redirect_url: `${process.env.REACT_APP_NGROK}/api/bookings/confirmation?bookingDetails=${encodedBookingDetails}`,
      });

      const { payment_url } = response.data;
      window.location.href = payment_url;
    } catch (error) {
      console.error('Error initiating payment:', error);

      if (error.response && error.response.data && error.response.data.message) {
        const { nextAvailableSlot } = error.response.data;
        setNextAvailableSlot(nextAvailableSlot);
        alert(`The selected time slot is not available. The next available slot is at ${new Date(nextAvailableSlot).toLocaleString()}`);
      } else {
        alert('An error occurred. Please try again.');
      }
    }
  };

  return (
    <div className="background">
      <h1 className="reservations-title">Book a Simulator</h1>
      <div className="reservations-container">
        <p className="reservations-description">
          Welcome to our reservation page. Please select a date and time for your session. Note that each booking is in hourly intervals.
        </p>
        <div className="simulator">
          <img
            src="https://swingtt.s3.us-east-2.amazonaws.com/sim1.jpg"
            alt="Simulator"
            className="simulator-image"
            loading="lazy"
          />
          <h2>Simulator</h2>
          <div className="form-container">
            <div className="form-column">
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                value={reservationDetails.firstName}
                onChange={handleInputChange}
                className="custom-input"
              />
            </div>
            <div className="form-column">
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={reservationDetails.lastName}
                onChange={handleInputChange}
                className="custom-input"
              />
            </div>
            <div className="form-column">
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={reservationDetails.email}
                onChange={handleInputChange}
                className="custom-input"
              />
            </div>
            <div className="form-column">
              <input
                type="tel"
                name="phone"
                placeholder="Phone"
                value={reservationDetails.phone}
                onChange={handleInputChange}
                className="custom-input"
              />
            </div>
            <div className="form-column">
              <input
                type="date"
                name="date"
                value={reservationDetails.date}
                onChange={handleInputChange}
                className="custom-datepicker"
                style={{ fontSize: '1.5em', padding: '15px' }}
              />
            </div>
            <div className="form-column">
              <select
                name="time"
                value={reservationDetails.time}
                onChange={handleInputChange}
                className="custom-datepicker"
                style={{ fontSize: '1.5em', padding: '15px' }}
              >
                <option value="">Select Time</option>
                {intervals.map((interval, index) => (
                  <option key={index} value={interval}>
                    {interval}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-column">
              <select
                name="duration"
                value={reservationDetails.duration}
                onChange={handleInputChange}
                className="custom-datepicker"
                style={{ fontSize: '1.5em', padding: '15px' }}
              >
                {Array.from({ length: maxDuration }, (_, i) => i + 1).map((hour) => (
                  <option key={hour} value={hour}>
                    {hour} Hour{hour > 1 ? 's' : ''}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-column">
              <select
                name="lesson"
                value={reservationDetails.lesson}
                onChange={handleInputChange}
                className="custom-datepicker"
                style={{ fontSize: '1.5em', padding: '15px' }}
              >
                <option value={0}>None</option>
                <option value={1}>Kids Lesson</option>
                <option value={2}>Adult Lesson</option>
              </select>
            </div>

            <div className="form-column">
              <select
                name="simulator"
                value={reservationDetails.simulator}
                onChange={handleInputChange}
                className="custom-input"
                style={{ fontSize: '1.5em', padding: '15px' }}
              >
                <option value={1}>Sim Zone</option>
                <option value={2}>Golf Den</option>
              </select>
            </div>

            <div className="form-column-full">
              <button className="checkout-button" onClick={handleCheckoutClick}>
                Checkout
              </button>
            </div>
            {nextAvailableSlot && (
              <div className="next-available-slot form-column-full">
                <p>
                  The selected time slot is not available.
                  <br />
                  The next available slot is at <strong>{new Date(nextAvailableSlot).toLocaleString()}</strong>
                  <br />
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="pricing-table">
          <h3>Pricing</h3>
          <table>
            <thead>
              <tr>
                <th>Service</th>
                <th>11am - 5pm, Monday - Friday</th>
                <th>5pm - Closing, Monday to Friday. Saturday and Sunday</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Simulator Session</td>
                <td>$400</td>
                <td>$600</td>
              </tr>
              <tr>
                <td>Golf Lessons</td>
                <td>$400</td>
                <td>N/A</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="promotions-section">
          <h3>Coming Soon!</h3>
          <p>- Ladies’ Night</p>
          <br />
          <p>- League Night</p>
        </div>
        <div className="image-section">
          <img
            src="https://swingtt.s3.us-east-2.amazonaws.com/Capture-6.jpg"
            alt="Golf Lounge"
            loading="lazy"
          />
          <img
            src="https://swingtt.s3.us-east-2.amazonaws.com/Capture-20.jpg"
            alt="Golf Simulator"
            loading="lazy"
          />
          <img
            src="https://swingtt.s3.us-east-2.amazonaws.com/Capture-33.jpg"
            alt="Bar Area"
            loading="lazy"
          />
        </div>
      </div>

      {/* Custom Popup for Terms and Conditions */}
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup">
            <h2>Terms and Conditions</h2>
            <p>{termsAndConditions}</p>
            <div className="agree-section">
              <label>
                <input type="checkbox" checked={isAgreed} onChange={handleAgreeChange} />
                I agree to the Terms and Conditions
              </label>
            </div>
            <div className="buttons">
  <button className="continue-button" onClick={handleContinue} disabled={!isAgreed}>
    Continue
  </button>
  <button className="close-button" onClick={() => setShowPopup(false)}>
    Close
  </button>
</div>
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default ReservationsPage;
