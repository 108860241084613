import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/UserInfo.css';

const UserInfoPage = () => {
  const [formData, setFormData] = useState({ firstName: '', lastName: '', email: '', phone: '' });
  const navigate = useNavigate();
  const simulator = new URLSearchParams(window.location.search).get('simulator');
  const date = new URLSearchParams(window.location.search).get('date');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!simulator || !date) {
      alert('Simulator and date are required');
      return;
    }

    const apiKey = '458xf4izac'; // Ensure this is your correct API key
    const amount = '20.00';
    const currency = 'TTD';
    const redirectUrl = encodeURIComponent('http://localhost:3001/payment_success');
    const failUrl = encodeURIComponent('http://localhost:3001/payment_fail');
    const name = `${encodeURIComponent(formData.firstName)}%20${encodeURIComponent(formData.lastName)}`;
    const email = encodeURIComponent(formData.email);
    const phone = encodeURIComponent(formData.phone);

    const wipayURL = `https://tt.wipay2.me/swing_sports_bar_and_lounge_limited?key=${apiKey}&amount=${amount}&currency=${currency}&name=${name}&email=${email}&phone=${phone}&redirect_url=${redirectUrl}&fail_url=${failUrl}`;

    window.location.href = wipayURL;
  };

  return (
    <div className="user-info-container">
      <h1>Enter Your Information</h1>
      <form onSubmit={handleSubmit}>
        <input type="text" name="firstName" placeholder="First Name" onChange={handleInputChange} />
        <input type="text" name="lastName" placeholder="Last Name" onChange={handleInputChange} />
        <input type="email" name="email" placeholder="Email" onChange={handleInputChange} />
        <input type="tel" name="phone" placeholder="Phone Number" onChange={handleInputChange} />
        <button type="submit">Proceed to Payment</button>
      </form>
    </div>
  );
};

export default UserInfoPage;
