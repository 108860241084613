import React from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

const PaymentPage = () => {
  const location = useLocation();
  const { state } = location;
  const { firstName, lastName, email, phone, simulator, date, amount } = state;

  const handlePayment = async () => {
    try {
      const response = await axios.post('http://localhost:5550/api/payments/initiate', {
        firstName,
        lastName,
        email,
        phone,
        date,
        amount,
        key: '458xf4izac',  // Replace with your actual WiPay API key
        redirect_url: 'http://localhost:3000/response'  // Update this URL to your actual response URL
      });

      const { payment_url } = response.data;
      window.location.href = payment_url;
    } catch (error) {
      console.error('Error initiating payment:', error);
      alert('An error occurred. Please try again.');
    }
  };

  return (
    <div className="payment-container">
      <h1>Payment Page</h1>
      <p>You are booking {simulator} for {new Date(date).toLocaleString()}</p>
      <button onClick={handlePayment}>Proceed to Payment</button>
    </div>
  );
};

export default PaymentPage;
