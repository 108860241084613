import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Navbar.css';
 // Adjust the path to your logo image

const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleMobileMenuToggle = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="navbar-logo">
          <Link to="/">
            <img src={"/assets/images/logo3.png"} alt="Swing Logo" className="navbar-logo-img" />
          </Link>
        </div>
        <div className={`navbar-menu ${isMobileMenuOpen ? 'active' : ''}`}>
          <li className="navbar-item">
            <Link to="/reservations" className="navbar-link" onClick={() => setMobileMenuOpen(false)}>Book Now</Link>
          </li>
          <li className="navbar-item">
            <Link to="/about" className="navbar-link" onClick={() => setMobileMenuOpen(false)}>About</Link>
          </li>
          <li className="navbar-item">
            <Link to="/contact" className="navbar-link" onClick={() => setMobileMenuOpen(false)}>Contact</Link>
          </li>
          <li className="navbar-item">
            <Link to="/lessons" className="navbar-link" onClick={() => setMobileMenuOpen(false)}>Lessons</Link>
          </li>
          <li className="navbar-item">
            <Link to="/menu" className="navbar-link" onClick={() => setMobileMenuOpen(false)}>Menu</Link>
          </li>
        </div>
        <div className="navbar-toggle" onClick={handleMobileMenuToggle}>
          <span className="navbar-toggle-icon"></span>
          <span className="navbar-toggle-icon"></span>
          <span className="navbar-toggle-icon"></span>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
