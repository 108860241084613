import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import ReactPlayer from 'react-player';
import 'react-datepicker/dist/react-datepicker.css';
import '../styles/LandingPage.css';
import Footer from '../components/footer';

const LandingPage = () => {
  const [isLoading, setIsLoading] = useState(true); // To handle loading state
  const [selectedDate, setSelectedDate] = useState(null);
  const navigate = useNavigate();

  // Handle date selection and redirect to reservations page
  const handleDateSelect = (date) => {
    setSelectedDate(date);
    if (date) {
      const formattedDate = date.toISOString().split('T')[0]; // Format date as YYYY-MM-DD
      localStorage.setItem('selectedDate', formattedDate); // Store the selected date in local storage
      navigate(`/reservations?date=${formattedDate}`); // Redirect with selected date
    }
  };

  return (
    <div className="landing-container">
      <div className="header">
        {isLoading && <div className="loading-circle"></div>} {/* Loading spinner when the video is loading */}

        {/* Using ReactPlayer to handle the HLS video */}
        <ReactPlayer
          url="https://swingtt.s3.us-east-2.amazonaws.com/home.mp4" // URL of your HLS video
          playing={true}         // Auto-plays the video
          loop={true}            // Loops the video
          muted={true}           // Muted by default
          controls={false}       // Hide controls
          width="100%"           // Full width
          height="100%"          // Full height
          onReady={() => setIsLoading(false)}  // Set loading to false when video is ready
          onError={(e) => console.error('Error playing video:', e)}  // Log any errors
        />
      </div>

      {/* Book Now Section */}
      <div className="book-now-section">
        <h2>Book Now</h2>
        <div className="date-picker-container">
          <DatePicker
            selected={selectedDate}
            onChange={handleDateSelect}
            dateFormat="yyyy-MM-dd"
            placeholderText="Select a date"
            className="custom-datepicker"
          />
        </div>
      </div>

      <section className="about-section">
        <h2>About Us</h2>
        <p>
          Welcome to Swing, Trinidad’s first Golf simulator and lounge! At Swing, we&#39;re revolutionizing
          the game, making golf accessible to everyone in the newest and most social way possible.
          Step in to where every swing is a celebration of the sport&#39;s beauty, surrounded by the cozy
          ambiance and luxury of our lounge. Have a meal with us, sip on one of our many beverages,
          and immerse yourself in the joy of perfecting your swing on the range or conquering
          renowned courses from around the globe. Either way, just Swing, Sip, Repeat.
        </p>
      </section>

      <section className="features-section">
        <h2>Our Features</h2>
        <div className="features-grid">
          <div className="feature"onClick={() => navigate('/reservations')}>
            <h3>Golf Simulators</h3>
            <p>Experience the latest in golf simulation technology.</p>
          </div>
          <div className="feature" onClick={() => navigate('/about')}>
            <h3>Bar & Lounge</h3>
            <p>Enjoy a wide selection of drinks in a relaxing atmosphere.</p>
          </div>
          <div className="feature" onClick={() => navigate('/menu')}>
            <h3>Dining</h3>
            <p>Savor delicious meals crafted by our expert chefs.</p>
          </div>
        </div>
      </section>

      <section className="contact-section">
        <h2>Contact Us</h2>
        <p>
          Have any questions? Reach out to us at <a href="mailto:swingtthelp@gmail.com">swingtthelp@gmail.com</a>.
        </p>
      </section>
      
      <Footer />
    </div>
  );
};

export default LandingPage;
