import React, { useState, useEffect } from 'react';
import '../styles/ContactPage.css';
import Footer from '../components/footer'

const ContactPage = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [responseMessage, setResponseMessage] = useState('');

  useEffect(() => {
    const img = new Image();
    img.src = 'https://swingtt.s3.us-east-2.amazonaws.com/Capture-34.jpg';
    img.onload = () => {
      document.body.classList.add('contact-page');
      document.body.style.backgroundImage = `url(${img.src})`;
    };

    // Preload the background image by adding this line
    const preloadLink = document.createElement('link');
    preloadLink.rel = 'preload';
    preloadLink.href = img.src;
    preloadLink.as = 'image';
    document.head.appendChild(preloadLink);

    return () => {
      document.body.classList.remove('contact-page');
      document.body.style.backgroundImage = '';
    };
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_NGROK}/api/contact`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      if (response.ok) {
        setResponseMessage('Your message has been sent successfully!');
      } else {
        setResponseMessage(data.message || 'Failed to send message.');
      }
    } catch (error) {
      setResponseMessage('Error sending message. Please try again later.');
    }
  };

  return (
    <div>
      <div className="contact-page-container">
        <h1 className="contact-title">Contact Us</h1>
        <div className="contact-info">
          <a href="tel:+18682256346" className="info-item-link">
            <div className="info-item">
              <p>Phone: (868) 225-6346</p>
            </div>
          </a>
          <a href="mailto:swingtthelp@gmail.com" className="info-item-link">
            <div className="info-item">
              <p>Email: swingtthelp@gmail.com</p>
            </div>
          </a>
          <a href="https://instagram.com/swing.tt" target="_blank" rel="noopener noreferrer" className="info-item-link">
            <div className="info-item">
              <p>Instagram: @swing.tt</p>
            </div>
          </a>
        </div>
        <div className="contact-form">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea id="message" name="message" rows="5" value={formData.message} onChange={handleChange} required></textarea>
            </div>
            <button type="submit" className="contact-button">Send Message</button>
          </form>
          {responseMessage && <p>{responseMessage}</p>}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactPage;
