import React, { useEffect, useState } from 'react';
import '../styles/MenuPage.css';
import Footer from '../components/footer';

const MenuPage = () => {
  const [backgroundLoaded, setBackgroundLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = 'https://swingtt.s3.us-east-2.amazonaws.com/Capture-11.jpg'; // Optimized image URL
    img.onload = () => setBackgroundLoaded(true);

    // Preload the background image
    const preloadLink = document.createElement('link');
    preloadLink.rel = 'preload';
    preloadLink.href = img.src;
    preloadLink.as = 'image';
    document.head.appendChild(preloadLink);

    return () => {
      document.head.removeChild(preloadLink); // Clean up preload link
    };
  }, []);

  return (
    <div className={`menu-background ${backgroundLoaded ? 'lazyloaded' : ''}`}>
      <div className="menu-page">
        <h1 className="menu-title">Our Menu</h1>
        <div className="menu-section">
          {/* Display Food and Drinks Menu Side by Side */}
          <div className="menu-row">
            {/* Food Menu */}
            <div className="menu-column">
              <h2 className="menu-subtitle">Food Menu</h2>
              <div className="menu-image-container">
                <img
                  src="https://swingtt.s3.us-east-2.amazonaws.com/menu3.jpg"
                  alt="Food Menu"
                  className="menu-image"
                  loading="lazy"
                />
              </div>
            </div>
            {/* Drinks Menu */}
            <div className="menu-column">
              <h2 className="menu-subtitle">Drinks Menu</h2>
              <div className="menu-image-container">
                <img
                  src="https://swingtt.s3.us-east-2.amazonaws.com/drinksMenu.png"
                  alt="Drinks Menu"
                  className="menu-image"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MenuPage;
