import React from 'react';
import { FaEnvelope, FaInstagram, FaPhone } from 'react-icons/fa';
import '../styles/Footer.css';  // Importing the new CSS file

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-icons">
        <a href="mailto:swingtthelp@gmail.com" aria-label="Email">
          <FaEnvelope />
        </a>
        <a href="https://www.instagram.com/swing.tt" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
          <FaInstagram />
        </a>
        <a href="tel:+18682256346" aria-label="Phone">
          <FaPhone />
        </a>
      </div>
      <div className="footer-location">
        <a
          href="https://www.google.com/maps/search/?api=1&query=Briar+Place+10-14+Sweet+Briar+Road+St.+Clair+Port+of+Spain"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Location"
        >
          Briar Place, 10-14 Sweet Briar Road, St. Clair, Port of Spain
        </a>
      </div>
    </footer>
  );
};

export default Footer;
